// context/IntercomContext.tsx
'use client';

import logger from '@/layers/support/monitoring/logger';
import { IntercomInstance, IntercomSettings } from '@intercom/messenger-js-sdk/dist/types';
import React, { createContext, useContext, useEffect } from 'react';
import { useGlobalState } from './GlobalStateContext';

const LOG_MODULE = '[Presentation / Context / Intercom]';

interface Window {
    intercomSettings?: IntercomSettings;
    Intercom: IntercomInstance | undefined;
    attachEvent: Function;
}

interface IntercomContextProps {
  showIntercom: () => void;
  hideIntercom: () => void;
  updateIntercom: (data: Record<string, any>) => void;
  getVisitorId: () => Promise<string | undefined>;
  triggerFeedback: (data: Record<string, any>) => void;
}

const IntercomContext = createContext<IntercomContextProps | undefined>(undefined);

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

export const IntercomProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, profile } = useGlobalState();

  useEffect(() => {
    logger.debug(`${LOG_MODULE} IntercomProvider`, { INTERCOM_APP_ID, user, profile });

    if (!INTERCOM_APP_ID) {
      console.error('Intercom App ID is not defined');
      return;
    }

    loadIntercomScript();

    return () => {
      if (typeof window !== 'undefined' && window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [user, profile]);


  useEffect(() => {
    if (typeof window === 'undefined' || !window.Intercom) return;

    if (user && profile && profile.id) {
      logger.debug(`${LOG_MODULE} updateIntercom`, { user, profile });
      updateIntercom({
        app_id: INTERCOM_APP_ID,
        user_id: profile.id,
        name: profile.id,
        email: user.personal_info?.email,
        created_at: profile.created_at ? Math.floor(new Date(profile.created_at).getTime() / 1000) : undefined,
        "User Type": user.type,
      });
    } else {
      updateIntercom({ app_id: INTERCOM_APP_ID });
    }
  }, [user, profile]);


  const loadIntercomScript = () => {
    if (document.getElementById('intercom-script')) return;

    (function () {
      const w = window as any;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const i = function () {
          i.c(arguments);
        } as any;
        i.q = [];
        i.c = function (args: any) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.id = 'intercom-script';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + INTERCOM_APP_ID;
          const x = d.getElementsByTagName('script')[0];
          x.parentNode?.insertBefore(s, x);
        };
        l();
      }
    })();
  };

  const getVisitorId = async (): Promise<string | undefined> => {
    logger.debug(`${LOG_MODULE} getVisitorId`, { Intercom: window.Intercom });
    if (window.Intercom) {
      const visitorId = window.Intercom('getVisitorId');
      logger.debug(`${LOG_MODULE} Visitor ID`, { visitorId });
      return visitorId;
    } else {
      logger.warn(`${LOG_MODULE} Intercom is not initialized.`);
      return undefined;
    }
  };

  const showIntercom = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
  };

  const hideIntercom = () => {
    if (window.Intercom) {
      window.Intercom('hide');
    }
  };

  const updateIntercom = (data: Record<string, any>) => {
    if (window.Intercom) {
      window.Intercom('update', data);
    }
  };

  const triggerFeedback = (data: Record<string, any>) => {
    logger.debug(`${LOG_MODULE} triggerFeedback`, { data });

    if (window.Intercom && typeof window.Intercom === 'function') {
      if (!data.elementInfo) {
        logger.error(`${LOG_MODULE} Missing 'elementInfo' in feedback data.`);
        return;
      }

      // Construct the message as plain text
      const message = `Your comment here: ...\n\n` +
        `📄 Text Content:\n${data.elementInfo.text}\n\n` +
        `🛠 Reported Element:\n` +
        `${data.elementInfo.tag}${data.elementInfo.id ? `#${data.elementInfo.id}` : ''}${data.elementInfo.classes ? `.${data.elementInfo.classes.split(' ').join('.')}` : ''}`

      // Open Intercom messenger with pre-filled message
      showIntercom();
      window.Intercom('showNewMessage', message);

    } else {
      logger.warn(`${LOG_MODULE} Intercom is not initialized.`);
    }
  };

  return (
    <IntercomContext.Provider
      value={{
        showIntercom,
        hideIntercom,
        updateIntercom,
        getVisitorId,
        triggerFeedback,
      }}
    >
      {children}
    </IntercomContext.Provider>
  );
};

export const useIntercom = (): IntercomContextProps => {
  const context = useContext(IntercomContext);
  if (context === undefined) {
    throw new Error('useIntercom must be used within an IntercomProvider');
  }
  return context;
};
