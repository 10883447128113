'use client';
import logger from '@/layers/support/monitoring/logger';
import { UserType } from '@/types/modelEnums';
import { User } from '@/types/models';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getProfile } from '../services/users';
import { useGlobalState } from './GlobalStateContext';

const LOG_MODULE = 'FE [Presentation / Context / AuthContext]';

interface AuthContextType {
  isProfileLoading: boolean;
  refreshProfile: () => Promise<void>;
  userRole: string | null;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { user: globalUser, profile: globalProfile, setUser, setProfile } = useGlobalState();
  const { user } = useAuthenticator((context) => [context.user]);
  const [isProfileLoading, setIsProfileLoading] = useState<boolean>(false);
  const [userAttributes, setUserAttributes] = useState<any>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const profileFetchedRef = useRef(false);

  logger.debug(`${LOG_MODULE}`, { isProfileLoading, globalUser, globalProfile, user, userAttributes, userRole });

  const fetchProfile = useCallback(async (userId: string, userType: UserType) => {
    if (profileFetchedRef.current) return;
    setIsProfileLoading(true);
    try {
      const fetchedProfile = await getProfile(userId, userType);
      setProfile(fetchedProfile);
      profileFetchedRef.current = true;
    } catch (error) {
      console.error('Error fetching profile:', error);
    } finally {
      setIsProfileLoading(false);
    }
  }, [setProfile]);

  const refreshProfile = useCallback(async () => {
    if (globalUser) {
      profileFetchedRef.current = false;
      await fetchProfile(globalUser.id, globalUser.type);
    }
  }, [globalUser, fetchProfile]);

  useEffect(() => {
    const fetchAttributes = async () => {
      if (user && !userAttributes) {
        const attributes = await fetchUserAttributes();
        setUserAttributes(attributes);
        const role = attributes['custom:role']?.toLowerCase() || null;
        setUserRole(role);
      }
    };
    fetchAttributes();
  }, [user, userAttributes]);

  useEffect(() => {
    if (user && userAttributes && !globalUser) {
      const currentUser: User = {
        id: user.userId,
        type: userAttributes['custom:type'] as UserType,
        role: userAttributes['custom:role']?.toLowerCase(),
      };
      if (userAttributes['email']) {
        currentUser.personal_info = {
          email: userAttributes['email'] as string,
        };
      }
      setUser(currentUser);
    }
  }, [user, userAttributes, globalUser, setUser]);

  useEffect(() => {
    if (globalUser && !globalProfile && !profileFetchedRef.current) {
      fetchProfile(globalUser.id, globalUser.type);
    }
  }, [globalUser, globalProfile, fetchProfile]);

  const value = {
    isProfileLoading,
    refreshProfile,
    userRole,
  };

  return (
    <Authenticator.Provider>
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    </Authenticator.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
